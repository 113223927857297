<template>
  <div id="app">
  
    

  
    <router-view></router-view>
  
  </div>
</template>

<script>


export default {
  components: {
  }
  
}
</script>

<style lang="scss">
#app {
  text-align: center;
}

#nav{
  font-size: 2rem; 
}
#nav_footer{
  font-size: 2rem; 
}
a
{
color: #00695C;
}
 .link_item1
{
  position: absolute;
  left: 20%;
  top:100px;
 text-decoration: none;
}
.link_item2
{
  position: absolute;
  left: 60%;
  top:100px;
 text-decoration: none;
}
.link_item3
{
  position: absolute;
  left: 20%;
  bottom: 100px;
 text-decoration: none;
}
.link_item4
{
  position: absolute;
  left: 60%;
  bottom: 100px;
 text-decoration: none;
}
.link_item:hover
{
 text-decoration: none;
  
}

@media screen and (max-device-width: 1000px) {
  #nav_footer{
  font-size: 1.5rem; 
}
#nav{
  font-size: 1.5rem; 
}

 .link_item1
{
  position: absolute;
  left: 5%;
  top:5%;
 text-decoration: none;
}
.link_item2
{
  position: absolute;
  left: 50%;
  top:5%;
 text-decoration: none;
}
.link_item3
{
  position: absolute;
  left: 5%;
  top:20%;
 text-decoration: none;
}
.link_item4
{
  position: absolute;
  left: 50%;
  top:20%;
 text-decoration: none;
}
}

</style>


