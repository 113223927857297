<template>
  <div class="home">

    
     <img class="svg" :src="image" alt="">
   
     <h3>
       <span class="font_color2 letter4 ">
         Пока мы моделируем эффективные решения в области цифровизации вашего бизнеса, для нас моделируют сайт.
<p>
Компания Integr8 (Интегрейт) — это партнерство специалистов с глубоким знанием российского строительного бизнеса и международных экспертов в сфере цифровизации строительной отрасли. Наша миссия заключается в том, чтобы осуществлять диджитализацию строительной отрасли путем привнесения лучших мировых практик для дальнейшей их адаптации и локализации на российском рынке.</p>
<p>
  Контакты: <br>
im@integr8.ru <br>
+79161051860 <br>

  </p>
         </span>
     </h3>
     <p class="font_color2 letter4 ">Сайт появится через:</p>
  </div>
</template>

     <script src="//megatimer.ru/get/9c5be05d33ad1cfbe259fc07de14c75d.js"></script>

<script>

// @ is an alias to /src

export default {
  data() {
        return {
            image: '/assets/logo.svg'
        };
    },
   metaInfo: {
      title: '',
      titleTemplate: '',
      htmlAttrs: {
        lang: 'ru',
        amp: true
      }
    },
  name: 'Home',
  components: {
   
  },
}
</script>


<style lang="scss" scoped>
h1
{
   font-family: 'Muller';
  margin-top: 25vh;
    font-style: normal;
  font-weight:bold;
}

.svg
{
   margin-top: 22vh;
  width: 457px;
  height: 150px;
}
@media screen and (max-device-width: 1000px) {
  .svg
  {
     width: 300px;
  height: 90px;
  margin-top: 10vh;
  }
  .letter4
  {
    font-size: 16px !important;
  }

}
.letter4{
  font-family: 'Muller';
    font-weight: 100;
    font-style: normal;
  font-size: 20px;  
}
.letter
{
  margin-bottom: 0;
}

.font_color1
{
  color: #FFA000;
}
.font_color2
{
  color: #424242;
}

.font_size
{
  font-size: 80px;
}

.font_size1
{
  font-size: 20px;
}


</style>
