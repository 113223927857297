import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {TimelineMax,TweenMax} from 'gsap'
import VueMeta from 'vue-meta'
import VueParticlesBg from 'particles-bg-vue' 
import VueAnalytics from 'vue-analytics' 



Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.use(VueAnalytics, {
  id: 'UA-159674941-1'
})



Vue.use({TimelineMax,TweenMax});
Vue.use(VueParticlesBg);

Vue.config.productionTip = false;

new Vue({
  router,
  render: function (h) { return h(App) },


}).$mount('#app')
